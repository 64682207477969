import { DataFieldProps, Form } from '@innedit/formidable';
import { UtilisateurModel } from '@innedit/innedit-react';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../components/Content';
import Layout from '../components/Layout';

const LoginPage: FC<PageProps> = function ({ location: { pathname } }) {
  const handleOnSubmit = (values: { email: string }) => {
    if (values.email) {
      UtilisateurModel.sendSignInLinkToEmail(values.email);
    }
  };

  return (
    <Layout pathname={pathname} showTitle title="pages.login.title">
      <Content className="mx-6 md:mx-auto !max-w-screen-sm">
        <Form
          datas={[
            {
              componentType: 'input',
              labelShow: false,
              name: 'email',
              placeholder: 'Adresse email',
              required: true,
              type: 'text',
            } as DataFieldProps,
          ]}
          footerProps={{
            className: 'flex items-center justify-center',
          }}
          name="login"
          onSubmit={handleOnSubmit}
          submitProps={{
            className: 'mt-12',
            label: 'Envoyer un email de connexion',
          }}
        />
      </Content>
    </Layout>
  );
};

export default LoginPage;
